import Cookies from 'js-cookie';
import _ from 'lodash';

import { cookieKeys } from '~/utils/Constants';
import useQuery from '~/hooks/useQuery';
import useRequest from '~/hooks/useRequest';
import axios from '~/services/axios';

export const Colleges = {
  get: shortName => useQuery(`/offer/colleges/${shortName}`),
  trends: (params, filters) => {
    return useQuery(`/offer/stats/result-trends?${params}${filters}`);
  },
  getCollegeSchools: shortName => useQuery(`/offer/college-schools?college_name=${shortName}`),
  getResultBackgrounds: (params, planyr) => {
    return useQuery(`/offer/stats/result-backgrounds?${params}&planyr=${planyr}`);
  },
  getRelated: shortName => useQuery(`/offer/colleges/${shortName}/related`),
  getThreads: (shortName, type, ps = 10, pg = 1, includes = 'summary') => {
    const fids = {
      review: '71',
      discuss: '',
      admission: '173',
      applications: '27',
      results: '82',
      group: '424',
    };
    return useQuery(`/api/tag-entities/${shortName}/threads?fid=${fids[type]}&ps=${ps}&pg=${pg}&includes=${includes}`);
  },
  getStatsResults: params => useQuery(`/offer/stats/results?${params}`),
  search: (filters, ps = 10, pg = 1, sortField) =>
    useQuery({
      url: `/offer/colleges-search?ps=${ps}&pg=${pg}`,
      method: 'post',
      data: {
        filters: _.pickBy(filters),
        sort_field: sortField,
      },
    }),
  getCases: (params, ps, pg = 1) => useQuery(`/offer/result-backgrounds?${params}&ps=${ps}&pg=${pg}`),
  getRatings: params => useQuery(`/offer/ratings?${params}`),
  getTrendingColleges: () => useQuery('/offer/trends/colleges'),
  getCnColleges: () => useQuery('/offer/cn-colleges'),
  getCNSchoolStats: (schoolName, planyr) => useQuery(`/offer/cn-colleges/${schoolName}?planyr=${planyr}`),
  getCnCollegeRelated: schoolName => useQuery(`/offer/cn-colleges/${schoolName}/related`),
};

export const Programs = {
  get: (programId, plainRequest = false) => useQuery(`/offer/programs/${programId}`, null, plainRequest),
  getRelated: programId => useQuery(`/offer/programs/${programId}/related`),
  search: (filters, ps, pg = 1) =>
    useQuery({
      url: `/offer/programs-search?ps=${ps}&pg=${pg}`,
      method: 'post',
      data: {
        filters: _.pickBy(filters),
      },
    }),
  recommend: (filters, ps, pg = 1) =>
    useQuery({
      url: `/offer/programs-recommend?ps=${ps}&pg=${pg}`,
      method: 'post',
      data: {
        filters: _.pickBy(filters),
      },
    }),
  getTrendingPrograms: () => useQuery('/offer/trends/programs'),
  getProgramReason: id => axios.get(`/offer/programs/${id}/reasons`),
  addNewProgram: params => axios.post(`/offer/programs`, params),
};

export const Favorites = {
  add: (name, type) => axios.post('/offer/favorites', { item_name: name, item_type: type }),
  get: (item_type, ps, pg) => useQuery(`/offer/favorites?item_type=${item_type}&ps=${ps}&pg=${pg}`),
  delete: (name, type) => axios.delete('/offer/favorites', { data: { item_name: name, item_type: type } }),
  getCnColleges: () => useQuery('/offer/cn-colleges'),
};

export const UserPrograms = {
  get: options => useQuery('/offer/user-programs/my', options),
  add: params => {
    const data = _.isObject(params)
      ? params
      : {
          notes: '',
          program_id: params,
          target_id: '2',
        };
    return axios.post('/offer/user-programs', data);
  },
  delete: pid => axios.delete(`/offer/user-programs/${pid}`),
  update: (pid, data) => axios.put(`/offer/user-programs/${pid}`, data),
  createResultThread: data => axios.post('/offer/user-programs/result-threads', data),
};

export const UserProgramReview = {
  getReviewList: () => useQuery('/offer/user-programs/reviews'),
  addReviewRequest: () => axios.post('/offer/user-programs/review-requests'),
  getReviewRequest: (id, options) => useQuery(`/offer/user-programs/review-requests/${id}`, options),
  updateReviewRequest: (id, data) => axios.put(`/user-programs/review-requests/${id}`, data),
  getReviewRequestList: () => useQuery('/offer/user-programs/review-requests'),
};

export const CollegeSchools = {
  get: id => useQuery(`/offer/college-schools/${id}`),
  getRelated: id => useQuery(`/offer/college-schools/${id}/related`),
};

export const Majors = {
  list: () => useQuery('/offer/majors'),
  get: (id, shortName) => {
    if (shortName) {
      return useQuery(`/offer/colleges/${shortName}/majors/${id}`);
    }
    return useQuery(`/offer/majors/${id}`);
  },
};

export const Results = {
  list: (filters, ps, pg = 1) =>
    useQuery({
      url: `/offer/results?ps=${ps}&pg=${pg}`,
      method: 'post',
      data: {
        filters: _.pickBy(filters),
      },
    }),
  getSubjectList: (filters, num = 3) =>
    useQuery({
      url: `/offer/subject-results?num=${num}`,
      method: 'post',
      data: {
        filters: _.pickBy(filters),
      },
    }),
  getMyResultThreads: (pg, ps) => useQuery(`/offer/result-threads?pg=${pg}&ps=${ps}`),
  getMyResults: (pg, ps) => useQuery(`/offer/results/my?pg=${pg}&ps=${ps}`),
  getMyAcceptedResult: () => useQuery('/offer/results/my/accepted'),
  acceptResult: id => axios.post(`/offer/results/${id}/accept`),
  unacceptResult: id => axios.post(`/offer/results/${id}/unaccept`),
  getResultDetail: id => useQuery(`/offer/results/${id}`),
  getUserOfferResult: uid => axios.get(`/offer/users/${uid}/results`),
  createResultThread: params => axios.post(`/offer/result-threads`, params),
};

export const Backgrounds = {
  getMyBackground: options => {
    const res = useQuery('/offer/backgrounds', options);
    return {
      hasBackground: !_.isEmpty(res?.data?.background),
      ...res,
    };
  },
  getMybackgroundThreadsReplies: () => useQuery('/offer/background-threads/replies'),
  add: data => axios.post('/offer/backgrounds', data),
  update: data => axios.put('/offer/backgrounds', data),
  report: (id, type, text) =>
    axios.post('/offer/backgrounds/report', {
      id,
      type,
      text,
    }),
  ask: (id, type) =>
    axios.post('/offer/backgrounds/ask', {
      id,
      type,
    }),
  getBackground: (uid, type, options) =>
    useQuery(type === 'uid' ? `/offer/users/${uid}/backgrounds` : `/offer/results/${uid}/backgrounds`, options),
  createBackgroundEvaluation: data => {
    return axios.post('/offer/background-threads', data);
  },
};

export const Stats = {
  getCompetition: () => useQuery('/offer/stats/background-competition'),
  getReleasedUniversities: () => useQuery('/offer/stats/released-universities'),
  getResultWeekNumber: planterm => axios.get(`/offer/stats/result-threads?planterm=${planterm}`),
  getBackgroundWeekNumber: planterm => axios.get(`/offer/stats/backgrounds?planterm=${planterm}`),
};

export const Summary = {
  getMySummaryThreads: (pg, ps) => useQuery(`/offer/users/articles/summary?pg=${pg}&ps=${ps}`),
};

export const Recommend = {
  colleges: (uids, country) =>
    useQuery(
      {
        url: `/offer/recommend/colleges`,
        method: 'post',
        data: {
          uids,
          country,
        },
      },
      { enabled: Boolean(uids.length) }
    ),
  students: country => useQuery(`/offer/recommend/students${_.isNil(country) ? '' : `?country=${country}`}`),
};

export const User = {
  getMyProfile: () => {
    const uid = Cookies.get(cookieKeys.BBS_UID_KEY);
    if (uid) {
      const { data, ...rest } = useQuery('/offer/user');
      return {
        user: data?.user,
        isLogin: !!data?.user,
        isVip: data?.user?.vip_type !== 0,
        ...rest,
      };
    }

    return {
      user: {},
      isLogin: false,
      isVip: false,
      isLoading: false,
    };
  },
  getFeed: (pg, ps, options) => {
    const res = useQuery(`/offer/user/feeds?ps=${ps}&pg=${pg}`, options);
    const lastpost = res?.data?.data?.[0]?.lastpost;
    if (pg === 1 && lastpost) {
      const prevTimestamp = localStorage.getItem('feed-timestamp');
      if (prevTimestamp) {
        const num = res.data.data.filter(v => v.lastpost > prevTimestamp).length;
        localStorage.setItem('feed-new', num < ps ? num : `${ps}+`);
      }
      localStorage.setItem('feed-timestamp', lastpost);
    }
    return res;
  },
  getMyThreads: (pg, ps) => useQuery(`/offer/user/threads?ps=${ps}&pg=${pg}`),
  getAccountPlan: () => useQuery('/offer/accounts/plans'),
  setPrivacyResult: actions => axios.post('/offer/accounts/privacy/result', { actions }),
  saveContact: contact => axios.post('/offer/accounts/privacy/contact', { contact }),
};
export const Pricing = {
  getPackages: category =>
    useQuery({
      method: 'get',
      baseURL: 'https://pay.1point3acres.com',
      withCredentials: false,
      url: `/api/packages?category=${category}`,
    }),
};

export const Discuz = {
  getForumThreads: ({ fid, gid, pg = 1, ps = 10, order, typeid = 0, includes = 'summary' }) => {
    return useQuery({
      url: `/api/forums/${fid ?? gid}/threads`,
      params: {
        ps,
        pg,
        includes,
        order,
        with_total: 1,
        is_groupid: gid ? 1 : null,
        typeid: typeid === 0 ? null : typeid,
      },
    });
  },
  getForumTypeThreads: (fid, typeid, filters = {}, order = 'time_desc', pg = 1, ps = 10, includes = 'summary') => {
    return useQuery({
      url: `/api/forums/${fid}/types/${typeid}/threads?includes=${includes}&order=${order}&ps=${ps}&pg=${pg}`,
      method: 'post',
      data: { filters },
    });
  },
  getCompareThreads: () => {
    return useQuery(`/offer/compare-threads`);
  },
  getEvaluationThreads: () => {
    return useQuery(`/offer/background-threads`);
  },
  getResultThreads: () => {
    return useQuery(`/offer/result-threads`);
  },
  postDiscussThread: params => {
    return axios.post(`/offer/discuss-threads`, params);
  },
  getAdmissionAdThreads: key => {
    const tids = [
      806625,
      790563,
      790340,
      796308,
      800305,
      791775,
      802838,
      721948,
      766320,
      640334,
      787798,
      719360,
      776111,
      775672,
      701957,
      739453,
    ];
    const random_tids = _.shuffle(tids).slice(0, 6);
    return useQuery(
      {
        url: `/api/specific-threads`,
        params: {
          includes: 'summary',
          tids: random_tids.join(','),
        },
      },
      { key }
    );
  },
};

export const Admission = {
  getResults: ({ filters, ps, pg }) =>
    useQuery({
      url: `/offer/admissions/results?ps=${ps}&pg=${pg}`,
      method: 'post',
      data: { filters },
    }),
  getResultStats: filters => useQuery(`/offer/admissions/stats?${new URLSearchParams(filters).toString()}`),
  getContact: id => axios.get(`/offer/members/results/${id}/contact`),
};

export const Fiverr = {
  reviews: ({ ps, pg, filters, order }) =>
    useRequest({
      url: `/offer/fiverr_reviews`,
      params: {
        ps,
        pg,
        order,
      },
      method: 'post',
      data: filters,
    }),
  editors: ({ ps, pg, filters, order }) =>
    useRequest({
      url: `/offer/fiverr_editors`,
      params: {
        ps,
        pg,
        order,
      },
      method: 'post',
      data: filters,
    }),
  popularEditors: () => useRequest(`/offer/popular_editors`),
  popularReviews: () => useRequest(`/offer/popular_editor_reviews`),
  editor: name => useRequest(`/offer/fiverr_editors/${name}`),
  relatedEditors: name => useRequest(`/offer/fiverr_editors/${name}/related`),
  typeahread: q => useRequest(`/offer/fiverr_editors/typeahead?q=${q}`, { enabled: q != null }),
};

export const Threads = {
  get: (tid, options) => useRequest(`/api/v3/threads/${tid}`, options),
  options: tid => useRequest(`/api/threads/${tid}/options`),
  posts: ({ tid, ps, pg }) =>
    useRequest({
      url: `/api/v3/threads/${tid}/posts`,
      params: {
        ps,
        pg,
      },
    }),
  addFavorite: tid =>
    axios({
      method: 'post',
      url: `/api/favorites/threads`,
      data: { tid, description: '' },
    }),
  reply: (tid, message, anonymous, quotePid) =>
    axios.post(`/api/threads/${tid}/posts`, {
      message,
      anonymous,
      quote_pid: quotePid,
    }),
  create: threadInfo => axios.post(`/api/threads`, threadInfo),
};

export const PS = {
  submitRequest: data =>
    axios({
      method: 'post',
      url: `/offer/ps`,
      data,
    }),
  submitDraftRequest: data =>
    axios({
      method: 'post',
      url: `/offer/ps-drafts`,
      data: { payload: data },
    }),
  updateRequest: (ps_id, data) =>
    axios({
      method: 'put',
      url: `/offer/ps/${ps_id}`,
      data,
    }),
  unLockPSDraft: ps_id =>
    axios({
      method: 'put',
      url: `/offer/ps-drafts/${ps_id}/unlock`,
    }),
  getDraftHistory: () => useRequest(`/offer/user/ps-drafts`),
  getRevisedPS: ps_id => useRequest(`/offer/ps/${ps_id}`, { enabled: Boolean(ps_id) }),
  getDraftedPS: ps_id => useRequest(`/offer/ps-drafts/${ps_id}`, { enabled: Boolean(ps_id) }),
};
