import 'core-js/stable';

import App from 'next/app';
import Head from 'next/head';
import React from 'react';
import NProgress from 'nprogress';
import Router from 'next/router';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { DefaultSeo } from 'next-seo';
import * as Sentry from '@sentry/browser';
import { ModalProvider } from 'react-use-modal';
import { Hydrate, QueryClientProvider } from 'react-query';
import { ConfigProvider } from 'antd';
import * as gtag from '../utils/GAtag';
import { queryClient } from '~/utils/QueryClient';
import ProtectRoute from '~/components/ProtectRoute';

import '~/styles/antd-override.css';
import '~/styles/tailwind.css';

moment.locale('zh-cn');

Sentry.init({
  dsn: 'https://39c193386a574f0b8e790efef50b498a@sentry.io/1726880',
  enabled: false,
});

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', url => {
  gtag.pageview(url);
  NProgress.done();
});
Router.events.on('routeChangeError', () => NProgress.done());

class MyApp extends App {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
      <ConfigProvider autoInsertSpaceInButton={false}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <ProtectRoute>
              <Head>
                <meta name="referrer" content="no-referrer-when-downgrade" />
                <meta
                  name="viewport"
                  content="width=device-width,minimum-scale=1,maximum-scale=1,initial-scale=1,viewport-fit=cover"
                />
              </Head>
              <DefaultSeo titleTemplate="%s | Offer多多" />
              <ModalProvider>
                <Component {...pageProps} />
              </ModalProvider>
            </ProtectRoute>
          </Hydrate>
        </QueryClientProvider>
      </ConfigProvider>
    );
  }
}

export default MyApp;
