import React from 'react';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { SEOMap } from '~/utils/seo_map';

const GeneralSEO = () => {
  const router = useRouter();
  const info = SEOMap[router.pathname];
  if (!info) {
    return null;
  }
  return (
    <NextSeo
      title={info.title}
      description={info.description}
      additionalMetaTags={[
        {
          property: 'keywords',
          content:
            '录取汇报,定位选校,申请总结,选校名单,大数据选校,智能选校,美国留学申请,澳大利亚留学申请,加拿大留学申请,英国留学申请,一亩三分地,Warald,申请背景',
        },
      ]}
      openGraph={{
        url: `https://offer.1point3acres.com${router.asPath}`,
        title: info.title,
        description: info.description,
        site_name: `${info.title} | Offer 多多`,
      }}
    />
  );
};

export default GeneralSEO;
