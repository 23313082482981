import { sprintf } from 'sprintf-js';

import Link from 'next/link';

const defaultPageSize = 15;

const cookieKeys = {
  BBS_USER_HASH: '4Oaf_61d6_cookie_hash',
  BBS_UID_KEY: '4Oaf_61d6_cuid',
};

const inputId = {
  MESSAGE: 'message',
  SUBJECT: 'subject',
  ANONYMOUS: 'anonymous',
};

const DOMAIN_ROOT = 'https://www.1point3acres.com/bbs';
const AVATAR_ROOT = 'https://avatar.1p3a.com';
const OOS_ROOT = 'https://oss.1p3a.com/offer/colleges/';

const getAvatarAddress = (uid, size = 'small', reload = false) => {
  // 已加防盗链，本地无法打开
  if (uid === undefined) {
    return `${AVATAR_ROOT}/noavatar_small.gif`;
  }
  const paddingUID = sprintf('%09d', uid).toString();
  const dir1 = paddingUID.substring(0, 3);
  const dir2 = paddingUID.substring(3, 5);
  const dir3 = paddingUID.substring(5, 7);
  const dir4 = paddingUID.substring(7);
  const url = `${AVATAR_ROOT}/${dir1}/${dir2}/${dir3}/${dir4}_avatar_${size}.jpg`;
  if (reload) {
    return `${url}?date=${new Date().getTime()}`;
  }
  return url;
};
const bbsEndpoint = {
  THREAD: tid => `${DOMAIN_ROOT}/thread-${tid}-1-1.html`,
  FORUM: fid => `${DOMAIN_ROOT}/forum-${fid}-1.html`,
  POST_THREAD: fid => `${DOMAIN_ROOT}/forum.php?mod=post&action=newthread&fid=${fid}`,
  POST_THREAD_WITH_SORT: (fid, sortid) =>
    `${DOMAIN_ROOT}/forum.php?mod=post&action=newthread&fid=${fid}&sortid=${sortid}`,
  AVATAR: uid => getAvatarAddress(uid),
  ABOUTUS: `https://www.1point3acres.com/bbs/aboutus.php`,
  BBS_USER_PROFILE: uid => `https://www.1point3acres.com/bbs/home.php?mod=space&uid=${uid}&do=profile`,
  WECHAT: 'https://auth.1point3acres.com/link/wechat',
  FORGET_PASSWORD: 'https://www.1point3acres.com/bbs/member.php?mod=logging&action=login&viewlostpw=1',
  LOGIN: path => (path ? `https://auth.1point3acres.com/login?url=${path}` : 'https://auth.1point3acres.com/login'),
  LOGOUT: path => `https://auth.1point3acres.com/logout?url=${path}`,
  COLLECTION: cid => `${DOMAIN_ROOT}/collection/${cid}`,
  COLLEGE_RATE_SORT: (outname, major = 0, sortId = 343) =>
    `https://www.1point3acres.com/bbs/forum.php?mod=forumdisplay&fid=71&filter=sortid&sortid=${sortId}&searchsort=1&searchoption%5B3024%5D%5Bvalue%5D=${outname}&searchoption%5B3001%5D%5Bvalue%5D=${major}`,
};
const InternalLink = ({ text, url, as = '' }) => {
  if (!as) {
    return (
      <Link href={url}>
        <a>{` ${text} `}</a>
      </Link>
    );
  }
  return (
    <Link href={url} as={as}>
      <a>{` ${text} `}</a>
    </Link>
  );
};

const offerEndpoint = {
  ADMIN: 'http://offer.1point3acres.com/admin',
};

export function toEmoji(unicode) {
  const unicodePoint = Number(unicode.replace(/^&#|;$/g, ''));
  return String.fromCodePoint(`0x${unicodePoint.toString(16)}`);
}

export function replaceEmojis(text) {
  return text.replace(/&#.+?;/g, toEmoji);
}

export const isServer = typeof window === 'undefined';

export const unitToText = unit => {
  return { 2: { text: '个月', num: 1 }, 3: { text: '年', num: 12 } }[unit];
};

export const headerMenus = [
  ['我的申请', '/my'],
  ['数据库', '/db'],
  ['定位选校', '/evaluation'],
  ['录取汇报', '/report'],
];

export const externalLinks = {
  fiverr: 'https://link.1point3acres.com/?url=https%3A%2F%2Fwww.fiverr.com',
  fiverr_editor: name => `https://link.1point3acres.com/?url=https%3A%2F%2Fwww.fiverr.com%2F${name}`,
};

export const vipBenefits = [
  '查看400个申请背景/月',
  '不受论坛积分限制',
  '使用智能推荐帮助选校',
  '解锁更多项目筛选方式',
  '统一管理申请流程',
  '订阅实时申请结果战报',
  '免费文书草稿生成',
];

export {
  bbsEndpoint,
  OOS_ROOT,
  DOMAIN_ROOT,
  cookieKeys,
  inputId,
  defaultPageSize,
  offerEndpoint,
  InternalLink,
  getAvatarAddress,
};
