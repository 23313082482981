import React from 'react';
import Link from 'next/link';
import { Layout } from 'antd';
import _ from 'lodash';

import Container from '~/components/common/Container';
import GeneralSEO from '~/components/seo/GeneralSEO';
import IconFont from '~/components/IconFont';
import useResponsive from '~/hooks/useResponsive';
import Header from './Header';

const { Content, Footer } = Layout;

const footerLeftItems = [
  { title: '关于我们', href: 'https://www.1point3acres.com/' },
  { title: '关于价格', href: '/pricing' },
  { title: '招生信息', href: 'https://www.1point3acres.com/bbs/forum-173-1.html' },
  { title: '找新生群', href: 'https://www.1point3acres.com/bbs/forum-424-1.html' },
  { title: '签证手续', href: 'https://www.1point3acres.com/bbs/forum-440-1.html' },
  { title: '找飞友', href: 'https://www.1point3acres.com/bbs/forum-424-1.html' },
  { title: '隐私协议', href: 'https://www.1point3acres.com/privacy_policy.html' },
  { title: '联系我们', href: 'https://www.1point3acres.com/contact' },
];

const footerLinks = _.flatten(
  [
    ['Computer Science', 'CS'],
    ['Electrical Engineering', 'EE'],
    ['Information Systems', 'MIS'],
    ['Mechanical Engineering', 'ME'],
    ['Biomedical Engineering', 'BME'],
    ['IEOR', 'IEOR'],
    ['Bioinformatics', 'BioInfo'],
    ['Accounting', 'Accounting'],
    ['Materials', 'Material'],
    ['Physics', 'Physics'],
    ['Civil Engineering', 'CivilEng'],
  ].map(([major, m]) =>
    [['Master', 'MS'], ['PhD', 'PhD'], ['Bachelor', 'BS']].map(([degree, d]) => ({
      title: `${major} ${degree} Programs`,
      href: `/db/programs/${m}-${d}-`,
    }))
  )
);

const PageLayout = ({
  background = '#fff',
  noContainer,
  noFooter,
  headerClassName = '',
  contentClassName = '',
  children,
}) => {
  const { mobile, md } = useResponsive();

  const footerNavMenu = (
    <div className="grid grid-cols-6 my-4 text-center md:text-lg">
      <Link href="/db">
        <a>数据库</a>
      </Link>
      <Link href="/my/preparations">
        <a>申请准备</a>
      </Link>
      <Link href="/my/targets">
        <a>定位选校</a>
      </Link>
      <Link href="/my/applications">
        <a>申请进展</a>
      </Link>
      <Link href="/my/decision">
        <a>比较决定</a>
      </Link>
      <Link href="/my/travel">
        <a>飞跃重洋</a>
      </Link>
    </div>
  );

  const footerLeftList = footerLeftItems.map(({ title, href }) => {
    if (_.startsWith(href, '/')) {
      return (
        <Link href={href} key={title}>
          <a className="text-xs md:text-sm text-#ABABAB block my-2">{title}</a>
        </Link>
      );
    }
    return (
      <a key={title} className="text-xs md:text-sm text-#ABABAB block my-2" href={href} target="_blank">
        {title}
      </a>
    );
  });

  return (
    <Layout>
      <GeneralSEO />
      <Header className={headerClassName} />
      <Content className={contentClassName}>
        {noContainer ? children : <Container className="md:mt-5">{children}</Container>}
      </Content>
      {!noFooter && mobile && (
        <Footer className="bg-white">
          <div className="mx-4 mt-1 mb-10">
            <div className="w-full flex justify-between items-end gap-x-2">
              <div className="flex-1 text-#5E5E5E text-center tracking-widest py-1 mt-4 mb-2 border-t-2 border-b-2">
                OFFER多多
                <br />飞 跃 重 洋
              </div>
              <Link href="/">
                <img className="mb-2 h-[70px] object-contain" src="/static/offer-dark.svg" />
              </Link>
              <div className="flex-1 text-#5E5E5E text-center tracking-widest py-1 mt-4 mb-2 border-t-2 border-b-2">
                启 航 明 天<br />追 寻 梦 想
              </div>
            </div>
            {footerNavMenu}
            <div className="grid grid-cols-4 text-center">{footerLeftList}</div>
            <div className="text-#ABABAB text-center my-2 pt-2 border-t-2">
              ©{new Date().getFullYear()} 1point3acres.com
            </div>
          </div>
        </Footer>
      )}
      {!noFooter && md && (
        <Footer className="bg-white">
          <div className="max-w-6xl m-auto flex">
            <div className="w-64">
              <Link href="/">
                <img className="mt-2 mb-4 h-16 object-contain" src="/static/offer-dark.svg" />
              </Link>
              {footerLeftList}
              <div className="text-#ABABAB">©{new Date().getFullYear()} 1point3acres.com</div>
            </div>
            <div className="flex-1">
              {footerNavMenu}
              <div className="text-#5E5E5E text-xl tracking-[5px] py-6 my-2 border-t-2 border-b-2 text-center">
                Offer多多 · 飞跃重洋 · 启航明天 · 追寻梦想
              </div>
              <div className="grid grid-cols-2 lg:grid-cols-3 gap-y-1">
                {footerLinks.map(({ title, href }) => {
                  if (_.startsWith(href, '/')) {
                    return (
                      <Link key={href} href={href}>
                        <a className="text-xxs lg:text-xs text-#ABABAB">{title}</a>
                      </Link>
                    );
                  }
                  return (
                    <a key={href} className="text-xs text-#ABABAB block my-1">
                      {title}
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </Footer>
      )}
      <div className="shadow-md fixed left-0 bottom-[100px] z-[200]">
        <div className="group flex">
          <div className="hidden md:block px-px py-0 rounded-r md:px-0 md:w-[32px] md:py-2 bg-primary group-hover:opacity-90">
            <a href="https://www.1point3acres.com/contact" target="_blank" className="no-underline">
              <div className="text-center">
                <IconFont type="icon-idea" className="iconfont icon-idea text-sm xl:text-lg text-white" />
              </div>
              <div className="mt-1 mb-2 px-1 text-base text-white text-center font-semibold leading-snug">我要反馈</div>
            </a>
          </div>
        </div>
      </div>
      <style jsx global>
        {`
          #__next {
            height: 100%;
          }
          .ant-layout {
            min-height: 100%;
          }
          .ant-layout-header {
            padding-left: 0;
            padding-right: 0;
            background: #497b89;
            z-index: 100;
          }
          .ant-layout-content {
            background: ${background};
          }
          .ant-layout-footer {
            z-index: 100;
          }
          ul.ant-menu {
            li {
              margin: 0px;
            }
          }
          @media screen and (max-width: 576px) {
            .ant-layout-header {
              padding: 0px 13px;
            }
            .ant-layout-footer {
              font-size: var(--font-size-xs-base);
              padding: 24px 0px;
            }
          }
        `}
      </style>
    </Layout>
  );
};

export default PageLayout;
